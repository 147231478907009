import $ from 'jquery';
import {strasbougLatLng} from "./_map-const";

let settings = strasbougLatLng;

const currentPin = (map, options) => {

    settings = Object.assign(settings, options);

    var myLatlng = new google.maps.LatLng(settings.lat, settings.lng);

    function CustomMarker(latlng, map, args) {
        this.latlng = latlng;
        this.args = args;
        this.setMap(map);
    }

    CustomMarker.prototype = new google.maps.OverlayView();

    CustomMarker.prototype.draw = function () {

        var self = this;
        var div = this.div;

        if (!div) {

            div = this.div = $('' +
                '<div>' +
                '<div class="pin"><div class="round"><div></div></div>' +
                '')[0];

            div.className = 'marker';
            div.style.position = 'absolute';

            if (typeof (self.args.marker_id) !== 'undefined') {
                div.dataset.marker_id = self.args.marker_id;
            }

            var panes = this.getPanes();

          if (panes) {
            panes.overlayImage.appendChild(div);
          }
        }

        var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

        if (point) {
            div.style.left = point.x + 'px';
            div.style.top = point.y + 'px';
        }
    };

    let pin = document.querySelector('.pin');
    if(pin){
        pin.remove();
    }


    CustomMarker.prototype.remove = function () {
        if (this.div) {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
        }
    };

    CustomMarker.prototype.getPosition = function () {
        return this.latlng;
    };

    var overlay = new CustomMarker(
        myLatlng,
        map,
        {}
    );

};

export default(
    currentPin
);
